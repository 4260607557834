<template>
    <Transition name="fade">
        <div v-if="show" class="loading-page">
            <img alt="loading" class="loading-page-image" src="../../assets/loading.png">
            <div class="loading-page-text">Loading{{ dots }}</div>
        </div>
    </Transition>
</template>

<script>
export default {
  name: 'PageLoading',
  props: {
    show: {
      type: Boolean,
      required: true,
    }
  },
  data () {
    return {
      dots: '...',
    }
  },
  created () {
    let count = 0
    setInterval(() => {
      this.dots = ''.padEnd(count, '.')
      count++
      if (count > 3) {
        count = 0
      }
    }, 500)
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.loading-page {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 6;

  &-image {
    max-height: 150px;
  }

  &-text {
    text-align: center;
    color: var(--rc-color-pink);
    /*text-shadow: 1px 1px 4px #0d0d0d;*/
    text-shadow: -1px -1px 3px #000, 1px -1px 3px #000, -1px 1px 3px #000, 1px 1px 3px #000;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Dela Gothic One', Avenir, Helvetica, Arial, sans-serif;
  }
}
</style>
