const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/Sites.vue'),
    meta: {
      scrollLock: true
    }
  },
  {
    name: 'sites',
    path: '/sites',
    component: () => import('./views/Sites.vue'),
  },
  {
    name: 'not-found-redirect',
    path: '/not-found',
    component: () => import('./views/NotFound.vue'),
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    component: () => import('./views/NotFound.vue'),
  },
]

module.exports = {
  routes
}
