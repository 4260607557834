<template>
    <RestrictArea class="restricted"></RestrictArea>
    <PageLoading :show="loading"/>
    <div class="d-flex flex-column h-100">
        <router-view class="flex-shrink-0"/>
        <FooterPage class="mt-auto py-3"></FooterPage>
    </div>
</template>
<script>
import FooterPage from './components/FooterPage'
import RestrictArea from './components/RestrictPrompt'
import PageLoading from './components/Site/PageLoading'

export default {
  components: {PageLoading, RestrictArea, FooterPage},
  data () {
    return {
      loading: false,
      showLoading: false,
    }
  },
  created () {
    this.$router.beforeEach((to, from, next) => {
      this.loading = true
      setTimeout(() => {
        if (this.loading) {
          this.showLoading = true
        }
      }, 100)
      next()
    })
    this.$router.afterEach(() => {
      this.loading = false
      this.showLoading = false
    })
  },
}
</script>
