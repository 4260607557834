import { routes } from '@/routes'
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (from, to, savedPosition) {
    if (to.meta.scrollLock && from.meta.scrollLock) {
      return savedPosition
    }

    return {top: 0, left: 0}
  },
})

export default router
