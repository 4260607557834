<template>
    <footer class="text-center text-white">
        <div class="copyright text-center p-3">
            Site Copyright ©2021 All Rights Reserved<br>
            Art and stories Copyright their artists/writers<br>
            Series & Characters Copyright their respective creators/studios<br>
            All characters depicted are 18 or older, even if otherwise specified.<br>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'FooterPage'
}
</script>

<style scoped>
.copyright {
    margin: 0;
    font-size: 12px;
    line-height: 1.4;
}
.link-general {
    margin-right: .3rem;
}
</style>
