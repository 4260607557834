<template>
    <div v-if="restriction" class="restrict d-flex justify-content-center align-items-center">
        <div class="restrict-box">
            <div class="row align-items-center mb-3">
                <p class="col-6">
                    This Website contains +18 content and it's restricted only for adults.
                    Are you an adult?
                </p>
                <div class="col-6">
                    <img alt="horny jail" class="restrict-img" src="../assets/restrict.png" title="horny jail">
                </div>
            </div>
            <div class="d-flex justify-content-around">
                <button class="btn restrict-btn restrict-btn-yes btn-success" @click.prevent="allow">Yes</button>
                <button class="btn restrict-btn restrict-btn-no btn-danger" @click.prevent="deny">No</button>
            </div>
        </div>
    </div>
</template>

<script>
const RESTRICTION_KEY = 'rc.restriction'
const restriction = JSON.parse(localStorage.getItem(RESTRICTION_KEY) || 'true')

export default {
  name: 'RestrictArea',
  data () {
    return {restriction}
  },
  methods: {
    allow () {
      localStorage.setItem(RESTRICTION_KEY, 'false')
      this.restriction = false
    },
    deny () {
      window.location.href = 'https://safebooru.org/index.php?page=post&s=list'
    },
  },
}
</script>

<style lang="scss" scoped>
.restrict {
  &-btn {
    font-weight: bold;
  }

  &-img {
    max-width: 100%;
  }

  &-box {
    max-height: 400px;
    max-width: 400px;
  }
}
</style>
